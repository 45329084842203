var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{staticClass:"card-AIP",style:({
    'border-left-width': '8px',
    'border-left-color': _vm.cardColor,
    width: !_vm.isDashboard || _vm.isMobile ? '100%' : '95%',
  }),on:{"click":function($event){$event.preventDefault();return _vm.toDetail(_vm.AIPSubject.id)}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('h3',[_vm._v(_vm._s(_vm.AIPSubject.slot.mata_pelajaran.nama))]),_c('div',{staticClass:"additionalAssignmentText"},[_vm._v(" Sess. "+_vm._s(_vm.AIPSubject.week.week)+"/"+_vm._s(_vm.totalWeeks)+" "+_vm._s(_vm.topik)+" ")])]),_c('a',{attrs:{"slot":"extra","href":"#"},slot:"extra"},[_c('a-icon',{style:({
        borderRadius: '4px',
        color: '#1B55E3',
        padding: '5px',
        background: `rgba(234, 240, 252, 0.9)`,
        fontSize: '1.5rem',
      }),attrs:{"type":_vm.iconAssignmentType}})],1),_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('p',{style:({
          color: _vm.cardColor,
          'font-size': '1rem!important',
        })},[_c('b',[_vm._v(_vm._s(_vm.AIPSubject.attendances.length && _vm.AIPSubject.attendances[0].submit_time && _vm.AIPSubject.attendances[0].submit_time !== "" ? "Submitted" : "Waiting to Submit"))])])]),_c('a-col',{attrs:{"span":12}},[_c('p',{style:({
          textAlign: 'right',
          color: _vm.cardColor,
          fontSize: '1rem!important',
        })},[_vm._v(" "+_vm._s(_vm.deadline)+" "),_c('a-icon',{attrs:{"type":"hourglass"}})],1)])],1),_c('br'),_c('p',{staticClass:"boxElipsis",style:({
      fontSize: '1rem!important',
    })},[_c('span',{ref:"deskripsi",domProps:{"innerHTML":_vm._s(_vm.tugasDeskripsi)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }