<template>
  <LoadingState v-if="isLoading" />
  <div v-else-if="dataOverall && !isLoading">
    <div id="coverDetailSubject">
      <!-- {{currentSubject}} -->
      <h2>{{ dataOverall.subject }}</h2>
    </div>
    <div id="contentDetailSubject">
      <a-row id="briefDetail" type="flex" class="mb-5">
        <a-col :xs="12" :lg="6">
          <label for="subject">Subject</label>
          <p>{{ dataOverall.subject }}</p>
        </a-col>
        <a-col :xs="12" :lg="6">
          <label for="teacher">Teacher</label>
          <p>{{ dataOverall.teacher }}</p>
        </a-col>
        <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
          <label for="progress">Week Progress</label>
          <p v-if="dataOverall.weekProgress">
            <span style="color: #1b55e3">{{
              dataOverall.weekProgress ? dataOverall.weekProgress.currentWeek : null
            }}</span>
            / {{ dataOverall.weekProgress ? dataOverall.weekProgress.totalWeek : null }} Week
          </p>
          <p v-else>No Data</p>
        </a-col>
        <a-col :xs="12" :lg="6" class="mt-4 mt-lg-0">
          <label for="assignments">Assignments</label>
          <p>{{
            dataOverall.averageGradedAssignment ?
              dataOverall.averageGradedAssignment.totalAssignment !== null ?
              dataOverall.averageGradedAssignment.totalAssignment
              : null
            : 'No Data' }} Session(s)</p>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" id="cardStats" type="flex" justify="center">
        <a-col :xs="24" :sm="24" :md="24" :lg="8">
          <a-card class="cardOverall" title="Class Presences">
            <div class="centeringBody" v-if="dataOverall.presence_setting == 'by session'">
              <div class="scoreText">{{ dataOverall.classPresences ? dataOverall.classPresences.presenceAverage : null }}%</div>
              <div class="additionalText">of</div>
              <div class="h3Text">{{ dataOverall.classPresences ? dataOverall.classPresences.totalAbsensi : null }}</div>
              <div class="additionalText">Session(s)</div>
            </div>
            <div class="centeringBody" v-else>
              <div class="scoreText">{{ dataOverall.classPresences ? dataOverall.classPresences.presenceAverage : null }}%</div>
              <div class="additionalText">of</div>
              <div class="h3Text">{{ dataOverall.classPresences ? dataOverall.classPresences.totalAbsensi : null }}</div>
              <div class="additionalText">Day(s)</div>
            </div>
          </a-card>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="8">
          <a-card class="cardOverall" title="Average Assignment Grade">
            <div class="centeringBody" v-if="dataOverall.averageGradedAssignment && dataOverall.averageGradedAssignment.totalAssignment !== 0">
              <div class="scoreText">{{ dataOverall.averageGradedAssignment ? dataOverall.averageGradedAssignment.averageGrade : null }}</div>
              <div class="additionalText">of</div>
              <div class="h3Text">{{ dataOverall.averageGradedAssignment ? dataOverall.averageGradedAssignment.totalAssignment : null }}</div>
              <div class="additionalText">assignments</div>
            </div>
            <div
              v-else
              class="font-size-18"
              style="color: #0000009c; margin: 3% 0"
            >
              No Data
            </div>
          </a-card>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="8">
          <a-card class="cardOverall" title="Newest Assignment Result">
            <a-row type="flex" align="middle">
              <template v-if="dataOverall.newestAssignmentResult">
                <a-col :span="20">
                  <div class="sessionCount">
                    <a-icon
                      style="color: #1b55e3"
                      type="unordered-list"
                      class="mr-3"
                    />
                    <span
                      >Session {{ dataOverall.weekProgress ? dataOverall.weekProgress.currentWeek : null }}/{{
                        dataOverall.weekProgress ? dataOverall.weekProgress.totalWeek : null
                      }}</span
                    >
                  </div>
                </a-col>
                <a-col :span="4">
                  <div class="scoreText">
                    {{ dataOverall.newestAssignmentResult }}
                  </div>
                </a-col>
              </template>
              <template v-else>
                <a-col :span="24">
                  <div
                    class="font-size-18"
                    style="color: #0000009c; margin: 3% 0"
                  >
                    No Data
                  </div>
                </a-col>
              </template>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <a-divider orientation="left">
        <h3>Summary of Assignment</h3>
      </a-divider>
      <a-row>
        <a-col :span="24">
          <div v-if="chartData.labels.length && chartData.series.length">
            <vue-chartist
              class="chartist-animated height-300 mt-4"
              type="Line"
              :data="chartData"
              :options="options"
            />
          </div>
          <div v-else>
            <EmptyState
              heading="No Data"
              description="You have no summary of assignment"
            />
          </div>
        </a-col>
      </a-row>
      <a-divider orientation="left">
        <h3>Assignment in Progress</h3>
      </a-divider>
      <a-row :gutter="[16, 16]" id="assignmentProgressCard">
        <template v-if="AIPCard && AIPCard.length > 0">
          <a-col
            :sm="24"
            :md="12"
            :lg="8"
            v-for="(AIPSubject, idx) in AIPCard"
            :key="idx"
            :class="[isMobile ? 'mb-3' : '']"
          >
            <CardAIP
              :totalWeeks="currentSubject.totalWeeks"
              :AIPSubject="AIPSubject"
            />
          </a-col>
        </template>
        <a-col v-else :span="24">
          <EmptyState
            heading="No Data"
            description="You have no assignment in progress"
          />
        </a-col>
      </a-row>
      <a-divider orientation="left">
        <h3>Summary of Past Topic</h3>
      </a-divider>
      <TableAIP
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import VueChartist from 'v-chartist'
// import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import CardAIP from '@/components/app/DetailSubject/CardAIP'
import TableAIP from '@/components/app/DetailSubject/TableAIP'
import EmptyState from '@/components/app/EmptyState'
import LoadingState from '@/components/app/LoadingState'

export default {
  components: {
    CardAIP,
    EmptyState,
    TableAIP,
    LoadingState,
    VueChartist,
  },
  data() {
    return {
      chartData: {
        labels: [],
        series: [],
      },
      options: {},
      dataTableAIP: [],
      dataOverall: {},
    }
  },
  methods: {
    fetchDataSummaryAssignment() {
      this.$store.dispatch('subject/FETCH_SUMMARY_ASSIGNMENT', {
        idMapel: this.$route.params.idSubject,
        idMurid: this.user.id,
      })
        .then(res => {
          const series = []
          res.dataChart.forEach((el, index) => {
            this.chartData.labels.push(`#${++index}`)
            series.push(el.tugas_nilai)
          })
          this.chartData.series.push(series)
          // console.log(this.chartData)
        })
    },
    fetchDataOverallSubject() {
      this.$store.dispatch('subject/FETCH_STUDENT_OVERALL_SUBJECT', {
        idMapel: this.$route.params.idSubject,
        idMurid: this.user.id,
        idKelas: this.user.id_kelas,
      })
        .then(res => {
          this.dataOverall = res
          console.log('this.dataOverall', this.dataOverall)
          // console.log(this.chartData)
        })
    },
  },
  async created() {
    try {
      // this.$store.dispatch('subject/FETCH_STUDENT_OVERALL', {
      //   idMapel: this.$route.params.idSubject,
      //   idMurid: this.user.id,
      //   idKelas: this.user.id_kelas,
      // })
      this.$store.dispatch('subject/FETCH_CARD_AIP', {
        idMapel: this.$route.params.idSubject,
        idMurid: this.user.id,
        idKelas: this.user.id_kelas,
      })
      this.fetchDataOverallSubject()
      this.fetchDataSummaryAssignment()
    } catch (err) {
      console.log(err)
    }
    // console.log(this.$store.state.user.user)
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    currentSubject() {
      return this.$store.state.subject.currentSubject
    },
    AIPCard() {
      const sessionInProgress = []
      if (this.currentSubject.AIPCard) {
        this.currentSubject.AIPCard.forEach(session => {
          if (session.tugas_deadline2) {
            sessionInProgress.push(session)
          }
        })
      }

      return sessionInProgress
    },
    sessionDone() {
      const newSesi = []
      this.currentSubject.studentOverall.sesi.forEach(sesi => {
        if (sesi.tanggal_sesi <= moment().format('YYYY-MM-DD')) {
          newSesi.push(sesi)
        }
      })

      return newSesi
    },
    assignmentTotal() {
      return this.$store.state.subject.assignmentTotal
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    averageGraded() {
      let gradeTotal = 0
      let assignmentTotal = 0
      // console.log('oke')
      this.currentSubject.studentOverall.sesi.forEach(sesi => {
        sesi.attendances.forEach(attendance => {
          if (attendance.tugas_nilai) {
            gradeTotal += attendance.tugas_nilai
            assignmentTotal++
          }
        })
      })

      let averageGrade = 0
      if (assignmentTotal > 0) {
        averageGrade = gradeTotal / assignmentTotal
      }
      averageGrade = parseFloat(averageGrade.toFixed(1))
      return {
        averageGrade,
        assignmentTotal,
      }
    },
    classPresences() {
      let studentPresent = 0
      this.currentSubject.studentOverall.sesi.forEach(sesi => {
        sesi.attendances.forEach(attendance => {
          if (attendance.status_attendance === 'present') {
            studentPresent++
          }
        })
      })

      const percentage = (studentPresent / this.sessionDone.length) * 100

      return !isNaN(percentage.toFixed(2)) ? percentage.toFixed(2) : 0
    },
    latestAssignmentResult() {
      let tugasNilai = 0
      let latestSession = '-'
      const now = moment().locale('id')
      this.currentSubject.studentOverall.sesi.forEach((sesi, i) => {
        sesi.attendances.forEach(attendance => {
          if (attendance.tugas_nilai) {
            if (latestSession === '-') {
              latestSession = sesi
            } else {
              if (moment(latestSession.tanggal_sesi, 'YYYY-MM-DD').diff(now, 'seconds') > moment(sesi.tanggal_sesi, 'YYYY-MM-DD').diff(now, 'seconds')) {
                latestSession = sesi
              }
            }
          }
        })
      })

      if (latestSession === '-') {
        return '-'
      } else {
        latestSession.attendances.forEach(attendance => {
          if (attendance.tugas_nilai) {
            tugasNilai = attendance.tugas_nilai
          }
        })

        return {
          week: latestSession.week.week,
          totalWeek: this.currentSubject.studentOverall.totalWeek,
          tugasNilai,
        }
      }
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>
<style lang="scss">
.cardOverall {
  min-height: 154px;
  .ant-card-body {
    height: 100%;
  }
}
#coverDetailSubject {
  width: 100%;
  min-height: 145px;
  background-color: var(--kit-color-primary);
  border-radius: 10px;
  padding: 40px;
  color: #ffffff;
  display: flex;
  align-items: center;
  h2 {
    color: #ffffff;
  }
  /* opacity: 0.8;
  z-index: 10; */
}

#contentDetailSubject {
  width: 100%;
  min-height: 20vh;
  background-color: white;
  padding: 40px;
  z-index: 11;
  margin-top: -10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
  margin-left: 1px;

  label {
    font: normal normal 600 17px/22px Montserrat;
    margin-bottom: 0;
  }

  #briefDetail {
    margin: 1% 0 3% 0;
  }

  .cardStats {
    .ant-card {
      border: none;
    }
  }

  .ant-card {
    width: 100%;
    box-shadow: 0px 0px 10px #0419301a;
    border-radius: 6px;
    height: 100%;
  }

  .scoreText {
    color: #41b883;
    font-size: 2.1rem;
    font-weight: 700;
  }

  .additionalText {
    font-size: 1rem;
  }

  #cardStats {
    margin-bottom: 3%;
  }

  .centeringBody {
    display: inline-flex;
    align-items: center;
    gap: 2%;
    flex-wrap: wrap;
    width: 100%;
  }

  .h3Text {
    font-family: "Montserrat", "sans-serif";
    font-size: 1.6rem;
    font-weight: 700;
    color: #041930;
  }

  .ant-card-head-title {
    font-weight: 700;
    font-size: 1.2rem;
  }

  .sessionCount {
    font-size: 1.2rem;
    font-weight: 700;
    color: #041930;
  }

  #assignmentProgressCard {
    margin-bottom: 4%;
    .additionalAssignmentText {
      color: #707788;
      font-family: "Mukta", "sans-serif";
      font-size: 0.9rem;
      font-weight: 500;
      margin-top: 2%;
      width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
